<template>
  <div id="app">
    <div class="header">
      <!--      <div class="sensors"><center>-->
      <!--          <img v-if="browseActive && backToStartActive" src="/assets/buttons/start-button-diap.png" alt="Start browse" @click="showStart">-->
      <!--        <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">-->
      <!--        <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">-->
      <!--      </center></div>-->
      <div class="sensors">
        <center>
          <div class="sensor-container" v-if="keyActive">
            <img src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelKeypad }}</div>
          </div>
          <div v-if="qrActive" class="sensor-container">
            <img src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelQR }}</div>
          </div>
          <div v-if="bbActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelList }}</div>
          </div>
          <div v-if="groupsActive" class="sensor-container">
            <img src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
            <div v-if="headerLabels" class="sensor-label">{{ headerLabelGroups }}</div>
          </div>
        </center>
      </div>
      <div class="home">
        <img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome">
        <div v-if="headerLabels" class="home-label">Home</div>
      </div>
      <div class="flag">
        <img v-if="langId !== 'x'" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
        <div v-if="headerLabels" class="flag-label">{{ headerLabelFlag }}</div>
      </div>
      <div class="divider">
        <center>
          <hr>
        </center>
      </div>
    </div>
    <div v-if="bbWide && bbNr" class="buttons-container">
      <div class="key-wide-left" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
        <div class="image-container">
          <div class="image-button"><img :src="item.image"></div>
          <div class="key-play"><img src="../assets/buttons/play.png"></div>
        </div>
        <!--              <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>-->
        <div class="key-label">
          <div class="nr" v-if="bbNr">{{ item.label }}:</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-if="bbWide && !bbNr" class="buttons-container">
      <div class="key-wide" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
        <!--              <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>-->
        <div class="key-label">
          <div class="nr" v-if="bbNr">{{ item.label }}</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-if="!bbWide" class="buttons-container">
      <div class="key" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';

export default {
  name: "ButtonBoard",
  props: ['langId'],
  data() {
    return {
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      qrActive: false,
      keyActive: false,
      bbActive: false,
      groupsActive: false,
      browseStart: false,
      browseActive: false,
      backToStartActive: false,
      bbWide: false,
      bbNr: false,
      headerLabels: false,
      headerLabelHome: '',
      headerLabelList: '',
      headerLabelGroups: '',
      headerLabelKeypad: '',
      headerLabelQR: '',
      headerLabelFlag: '',
      languageX: '',
      itemList: []
    }
  },
  methods: {
    showHome: function () {
      router.push('/');
    },
    showWelcome: function () {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function () {
      router.push('/intro/' + this.langId);
    },
    showStart: function () {
      if (this.browseStart && this.browseActive) {
        // open first subject in audiotour
        axios.get(this.apiUrl + 'items?tags=audio-01&collection=' + this.apiCollection + '&key=' + this.apiKey)
            .then((res) => {
                  let objectId = res.data[0].id;
                  router.push('/object/' + this.langId + '/' + objectId);
                }
            )
            .catch(err => console.log(err));
      } else {
        router.push('/intro/' + this.langId);
      }
    },
    showQR: function () {
      router.push('/qr/' + this.langId);
    },
    showKey: function () {
      router.push('/key/' + this.langId);
    },
    getObject: function (idIn) {
      router.push('/object/' + this.langId + '/' + idIn);
    },
    sortItems: function (itemsToSort) {
      return itemsToSort.sort(function (a, b) {
        return a.id - b.id;
      });
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
          this.keyActive = configData.keypad;
          this.browseActive = configData.browse;
          this.backToStartActive = configData.startbutton;
          this.browseStart = configData.browse_start;
          this.bbWide = configData.bbwide;
          this.bbNr = configData.bbnr;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.languageX = configData.language_x;
          // labels for header buttons
          this.headerLabels = configData.label_headerbuttons;
          this.headerLabelHome = configData.label_button_header_home[`${this.langId}`];
          this.headerLabelList = configData.label_button_header_list[`${this.langId}`];
          this.headerLabelGroups = configData.label_button_header_groups[`${this.langId}`];
          this.headerLabelKeypad = configData.label_button_header_keypad[`${this.langId}`];
          this.headerLabelQR = configData.label_button_header_qr[`${this.langId}`];
          this.headerLabelFlag = configData.label_button_header_flag[`${this.langId}`];
          //210324 - because of introduction new object-type: audio-group query has to include item_type:18 audiotour-item
          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=18&key=' + this.apiKey)
              .then((itemsResult) => {
                if (itemsResult) {
                  itemsResult.data.forEach((item) => {
                    item.element_texts.forEach((text_element) => {
                      if (text_element.element.name === 'Titel_' + this.langId) {
                        if (text_element.text !== '') {
                          item.tags.forEach((tagFound) => {
                            let tagArray = tagFound.name.split('-');
                            // Only tags from 11 and up
                            if (parseInt(tagArray[1]) > 10) {
                              if (tagArray[0] === 'audio' || tagArray[0] === 'audio') {
                                console.log(tagArray[0] + ': ' + text_element.text);
                                let itemToPush = {};
                                //=========================
                                axios.get(this.apiUrl + 'files?item=' + item.id + '&key=' + this.apiKey)
                                    .then((filesResult) => {
                                      if (filesResult) {
                                        filesResult.data.forEach((fileFound) => {
                                          if (fileFound.mime_type === 'image/jpeg' || fileFound.mime_type === 'image/png') {
                                            itemToPush.image = fileFound.file_urls.thumbnail;
                                          }
                                        });
                                      }
                                      if (this.langId === 'gt') {
                                        itemToPush.image = 'assets/buttons/flags/gt_small.jpg';
                                      }
                                      // this.itemList.push(itemToPush);
                                      let tagNr = parseInt(tagArray[1]);
                                      itemToPush.id = item.id;
                                      itemToPush.tagnr = tagNr;
                                      itemToPush.label = tagArray[1];
                                      itemToPush.title = text_element.text;
                                      this.itemList.push(itemToPush);
                                      this.itemList = this.sortItems(this.itemList);
                                    })
                                    .catch((filesErr) => {
                                      console.log('error files: ' + filesErr);
                                    })
                                //=========================
                                // itemToPush.id = item.id;
                                // itemToPush.label = tagArray[1];
                                // itemToPush.title = text_element.text;
                                // this.itemList.push(itemToPush);
                              }
                            }
                          });
                        }
                      }
                    });
                  });
                  console.log('itemList: ' + JSON.stringify(this.itemList));
                } else {
                  console.log('nothing found!');
                }
              })
              .catch((err) => {
                console.log('created(): error getting itemlist: ' + err)
              });
        })
        .catch((err) => {
          console.log('created(): error config: ' + err)
        });
  }
}
</script>

<style scoped>
.buttons-container {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 600px;
}

.buttons-container .key {
  display: inline-flex;
  flex: 0 0 30%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
}

.buttons-container .key-wide {
  display: inline-flex;
  flex: 0 0 100%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.bb-container .key-wide-left {
  display: inline-flex;
  width: 100%;
  /*flex: 0 0 100%;*/
  margin-bottom: 1px;
  font-family: 'adelleregular', 'Courier New', Courier, monospace;
  font-size: 1.3em;
  font-weight: bold;
  height: 70px;
  color: white;
  text-align: left;
}

.buttons-container .key-wide-left {
  display: inline-flex;
  /*flex: 0 0 100%;*/
  width: 100%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 18px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: left;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.buttons-container .key span {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}

.buttons-container .key-wide div {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}

/*.buttons-container .key-wide div span.nr {*/
/*    width: 30%;*/
/*    height: 100%;*/
/*    text-align: center;*/
/*    padding-top: 15px;*/
/*}*/
/*.buttons-container .key-wide-left div {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    text-align: left;*/
/*    padding-top: 15px;*/
/*}*/
/*.buttons-container .key-wide-left div span.nr {*/
/*    width: 30%;*/
/*    height: 100%;*/
/*    text-align: left;*/
/*    padding-top: 15px;*/
/*}*/
/* van synagoge */
.buttons-container .key-wide-left .image-container {
  position: relative;
  width: 20%;
  height: 100%;
  max-width: 80px;
  margin-bottom: auto;
  margin-top: auto;
  /*background-color: #584C4C;*/
  text-align: center;
}

.buttons-container .key-wide-left .image-container .key-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 80px;
  margin-bottom: auto;
  margin-top: auto;
  background-color: transparent;
  text-align: center;
}

.buttons-container .key-wide-left .image-container .key-play img {
  width: 30%;
  height: 100%;
  object-fit: contain;
}

.buttons-container .key-wide-left .image-container .image-button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 80px;
  margin-bottom: auto;
  margin-top: auto;
  /*background-color: #584C4C;*/
  /*text-align: center;*/
}

.buttons-container .key-wide-left .image-container .image-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons-container .key-wide-left .key-play {
  position: relative;
  width: 25%;
  height: 100%;
  max-width: 80px;
  margin-bottom: auto;
  margin-top: auto;
  background-color: #584C4C;
  text-align: center;
}

.buttons-container .key-wide-left .key-play img {
  width: 30%;
  height: 100%;
  object-fit: contain;
}

.buttons-container .key-wide-left .image-button {
  width: 25%;
  height: 100%;
  max-width: 80px;
  margin-bottom: auto;
  margin-top: auto;
  /*background-color: #584C4C;*/
  /*text-align: center;*/
}

.buttons-container .key-wide-left .image-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons-container .key-wide-left .key-label {
  display: flex;
  align-items: center;
  width: 70%;
  margin-left: 5px;
  /*height: 100%;*/
  text-align: left;
  /*padding-top: 8px;*/
}

.buttons-container .key-wide-left .key-label .nr {
  width: 15%;
  /*height: 100%;*/
  text-align: left;
  /*padding-top: 15px;*/
}

.buttons-container .key-wide-left .key-label .title {
  width: 85%;
  /*height: 100%;*/
  text-align: left;
  /*padding-top: 15px;*/
}

/* einde van synagoge */

</style>
